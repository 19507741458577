<template>
  <v-container fluid>
    <validation-observer ref="repValObserver">
      <v-row>
        <v-col
          cols="12"
        >
          <span style="font-size: x-large; font-weight: 450;">BoardMatch Representative Information</span>
        </v-col>
        <v-col cols="6">
          <validation-provider
            v-slot="{ errors }"
            name="Board Position"
            rules="required"
          >
            <v-autocomplete
              v-model="inviteeData.boardMatchRepresentativeRoleId"
              label="Select Role"
              :error-messages="errors"
              :items="boardmatchRepRoles"
              item-text="name"
              required
              return-object
            >
              <template #selection="{ item }">
                <span class="black--text">
                  {{ item.name }}
                </span>
              </template>
              <template #label>
                Select Role<span class="red--text"><strong>* </strong></span>
              </template>
              <template #item="{ item }">
                <span class="black--text">
                  {{ item.name }}
                </span>
              </template>
            </v-autocomplete>
          </validation-provider>
        </v-col>
        <v-col
          cols="6"
        >
          <validation-provider
            v-slot="{ errors }"
            name="Email"
            :rules="'required|email'"
          >
            <v-text-field
              v-model="inviteeData.email"
              :error-messages="errors"
              required
            >
              <template #label>
                Email<red-asterisk />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-row class="pa-3">
          <v-col
            cols="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="First Name"
              rules="required"
            >
              <v-text-field
                v-model="inviteeData.firstName"
                required
                :error-messages="errors"
              >
                <template #label>
                  First Name<red-asterisk />
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col
            cols="6"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Last Name"
              rules="required"
            >
              <v-text-field
                v-model="inviteeData.lastName"
                :error-messages="errors"
                required
              >
                <template #label>
                  Last Name<red-asterisk />
                </template>
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from 'vee-validate';
import { mapState } from 'vuex';

setInteractionMode('eager');

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props:
  {
    boardmatchRepData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    roles: [],
    inviteeData: {
      firstName: '',
      lastName: '',
      email: '',
      boardMatchRepresentativeRoleId: null,
    },
  }),
  computed: {
    ...mapState({
      boardmatchRepRoles: (state) => state.general.boardMatchRepRoles,
    }),
  },
  watch: {
    boardmatchRepData: {
      immediate: true,
      handler(value) {
        if (value) {
          this.inviteeData = {
            firstName: value.userInformation.firstName,
            lastName: value.userInformation.lastName,
            email: value.userName,
            boardMatchRepresentativeRoleId: value.boardmatchRepresentative.boardmatchRepresentativeRole[0].id,
          };
        }
      },
    },
  },
  created() {
    this.$store.dispatch('general/getBoardMatchRoles');
  },
  methods: {
    async getRoles() {
      this.roles = await this.$api.boardmatchRepRoles.get();
    },
    async validate() {
      const res = await this.$refs.roleValObserver.validate();
      return res;
    },
    async submit() {
      const submitObject = { ...this.inviteeData, boardMatchRepresentativeRoleId: this.inviteeData.boardMatchRepresentativeRoleId.id };
      const rep = await this.$api.boardmatchRep.sendInvite(submitObject);
      return rep;
    },
    reset() {
      this.$refs.repValObserver.reset();
      console.log('reset');
      this.inviteeData = {
        firstName: '',
        lastName: '',
        email: '',
        boardMatchRepresentativeRoleId: null,
      };
    },
  },
};
</script>
