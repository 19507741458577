<template>
  <v-container
    fluid
    class="pa-2 pb-2 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-row>
      <v-col
        cols="12"
        class="pa-6"
      >
        <h1>Candidates</h1>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="2">
        <v-tabs
          v-model="isDisabledTab"
          center-active
        >
          <v-tab>Active</v-tab>
          <v-tab>Inactive</v-tab>
        </v-tabs>
      </v-col>
      <v-col
        cols="2"
        class="pa-3 pr-6"
      >
        <v-autocomplete
          v-model.lazy="selectedSkills"
          :items="skills"
          :label="'Skills'"
          item-text="name"
          item-value="id"
          hide-details=""
          x-large
          outlined
          clearable
          multiple
        />
      </v-col>
      <v-col
        cols="2"
        class="pa-3 pr-6"
      >
        <v-autocomplete
          v-model.lazy="selectedFocusAreas"
          :items="focusAreas"
          :label="'Causes'"
          item-text="name"
          item-value="id"
          hide-details=""
          large
          outlined
          clearable
          multiple
        />
      </v-col>
      <v-col
        cols="2"
        class="pa-3 pr-6"
      >
        <v-autocomplete
          v-model.lazy="selectedCity"
          :items="candidateCities"
          :label="'City'"
          item-text="name"
          item-value="name"
          prepend-inner-icon="mdi-navigation-variant"
          hide-details=""
          x-large
          outlined
          clearable
          multiple
        />
      </v-col>
      <v-col
        cols="2"
        class="pa-3 pr-6"
      >
        <v-select
          v-model.lazy="selectedProvince"
          :items="candidateProvince"
          :label="'Province'"
          item-text="name"
          item-value="name"
          prepend-inner-icon="mdi-home-group"
          hide-details=""
          x-large
          outlined
          clearable
          multiple
          @clear="selectedProvince = ''"
        />
      </v-col>
      <v-col
        cols="2"
        class="text-end pa-3 pr-6"
      >
        <v-btn
          color="primary"
          outlined
          @click="openCandidateCreateForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New Candidate
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <candidate-table
          ref="candidateTable"
          :filters="filters"
        >
          <template slot-scope="{item}">
            <template>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <font-awesome-icon
                    class="icon-hover mr-2"
                    :icon="['fabs', 'user-pen']"
                    v-bind="attrs"
                    v-on="on"
                    @click="authAs(item)"
                  />
                </template>
                <span>Login As (To Edit Candidate Details)</span>
              </v-tooltip>
              <v-tooltip
                v-if="isDisabledTab == 0"
                top
              >
                <template #activator="{ on, attrs }">
                  <font-awesome-icon
                    class="icon-hover mr-3"
                    v-bind="attrs"
                    :icon="['fabs', 'xmark']"
                    v-on="on"
                    @click="disableCandidate(item.id)"
                  />
                </template>
                <span>Deactivate candidate</span>
              </v-tooltip>
            </template>
            <template>
              <v-tooltip
                v-if="isDisabledTab == 1"
                top
              >
                <template #activator="{on, attrs}">
                  <font-awesome-icon
                    class="icon-hover mr-3"
                    :icon="['fabs', 'circle-plus']"
                    v-bind="attrs"
                    v-on="on"
                    @click="disableCandidate(item.id)"
                  />
                </template>
                <span>Activate candidate</span>
              </v-tooltip>
            </template>
            <v-tooltip top>
              <template #activator="{on, attrs}">
                <font-awesome-icon
                  class="icon-hover"
                  style="color:red"
                  :icon="['fabs', 'trash-can']"
                  v-bind="attrs"
                  v-on="on"
                  @click="softDelete(item.id)"
                />
              </template>
              <span>Delete candidate</span>
            </v-tooltip>
          </template>
        </candidate-table>
      </v-col>
    </v-row>
    <candidate-template-dialog
      ref="candidateTemplateDialog"
      :title="candidateDialogTitle"
      :user="user"
      @candidate-success="notifySuccess"
    >
      <template slot-scope="{item}">
        <v-btn
          v-if="currentUser != null && item != null && currentUser.id != item.id"
          class="mr-4"
          color="primary"
          @click="authAs(item)"
        >
          Log In As User
        </v-btn>
      </template>
    </candidate-template-dialog>
    <user-authenticate-as-dialog
      ref="authenticateAsDialog"
    />
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import CandidateTemplateDialog from '@/components/candidate/CandidateTemplateDialog';
import CandidateTable from '@/components/candidate/CandidateTable.vue';
import UserAuthenticateAsDialog from '@/components/user/UserAuthenticateAsDialog';

export default {
  components: {
    CandidateTemplateDialog,
    CandidateTable,
    UserAuthenticateAsDialog,
  },
  data: () => ({
    candidates: [],
    candidatesPerPageProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    candidatesPerPage: 20,
    loading: false,
    user: null,
    isDisabledTab: 0,
    candidateDialogTitle: 'Create Candidate',
    pagination: null,
    total: 0,
    selectedSkills: [],
    selectedFocusAreas: [],
    selectedProvince: null,
    selectedCity: null,
    candidateCities: [],
    candidateProvince: [],
  }),
  computed: {
    filters() {
      const filters = [];
      filters.push({ name: 'AdminOverride', values: [1] });
      filters.push({ name: 'IsDisabled', values: [`${this.isDisabledTab}`] });
      // skills
      if (this.selectedSkills && this.selectedSkills.length > 0) {
        filters.push({ name: 'skillSet', values: this.selectedSkills });
      }
      // focusAreas
      if (this.selectedFocusAreas && this.selectedFocusAreas.length > 0) {
        filters.push({ name: 'focusAreas', values: this.selectedFocusAreas });
      }
      // city
      if (this.selectedCity && this.selectedCity.length > 0) {
        filters.push({ name: 'City', values: this.selectedCity });
      }
      if (this.selectedProvince && this.selectedProvince.length > 0) {
        filters.push({ name: 'Province', values: this.selectedProvince });
      }
      return filters;
    },
    ...mapState({
      currentUser: (state) => state.user,
      skills: (state) => state.candidate.candidateSkills,
      focusAreas: (state) => state.candidate.candidateFocusAreas,
    }),
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.loadingData = Promise.all(
        [
          await this.loadTypes(),
          await this.getCandidateCities(),
          await this.getCandidateProvince(),
        ],
      );
      await this.loadingData;
    },
    async loadTypes() {
      const promises = [];
      if (!this.skills.length) promises.push(this.$store.dispatch('candidate/getCandidateSkills'));
      if (!this.focusAreas.length) promises.push(this.$store.dispatch('candidate/getCandidateFocusAreas'));
      await Promise.all(promises);
    },
    openCandidateCreateForm() {
      this.candidateDialogTitle = 'Create Candidate';
      this.user = null;
      this.$refs.candidateTemplateDialog.openCandidateForm();
    },
    openCandidateUpdateForm(candidate) {
      this.candidateDialogTitle = 'Update Candidate';
      this.user = JSON.parse(JSON.stringify(candidate.user));
      this.user.candidate = JSON.parse(JSON.stringify(candidate));
      this.$refs.candidateTemplateDialog.openCandidateForm();
    },

    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$refs.candidateTable.refresh();
    },

    async disableCandidate(candidateId) {
      if (!confirm('Are you sure you would like to deactivate this candidate?')) return;
      const response = await this.$api.candidate.disableCandidate(candidateId);
      if (response === false) return;
      this.notifySuccess('Candidate disabled');
    },
    async softDelete(candidateId) {
      if (!confirm('Are you sure you would like to delete this candidate?')) return;
      const response = await this.$api.candidate.softDelete(candidateId);
      if (response === true) {
        this.notifySuccess('Candidate deleted');
      } else {
        this.$bus.$emit('showError');
      }
    },
    authAs(user) {
      this.$refs.authenticateAsDialog.openDialog(user);
    },
    async getCandidateCities() {
      const cities = await this.$api.candidate.getCities();
      this.candidateCities = [];
      cities.forEach((x) => {
        if (x !== null) {
          const splitName = x.split(' ');
          for (let i = 0; i < splitName.length; i += 1) {
            if (splitName[i][0]) splitName[i] = splitName[i][0].toUpperCase() + splitName[i].substr(1);
          }
          this.candidateCities.push({ name: splitName.join(' '), value: x });
        }
      });
    },
    async getCandidateProvince() {
      const province = await this.$api.candidate.getProvince();

      this.candidateProvince = province.map((x) => {
        const splitName = x.split(' ');
        for (let i = 0; i < splitName.length; i += 1) {
          if (splitName[i][0]) splitName[i] = splitName[i][0].toUpperCase() + splitName[i].substr(1);
        }
        return splitName.join(' ');
      });
    },
  },
};
</script>

<style>
.icon-hover:hover {
  cursor: pointer;
}
</style>
