<template>
  <v-container
    fluid
    class="pa-2 pb-2 ma-2 mt-12"
    style="background-color: white; border-radius: 8px;"
  >
    <v-row class="ma-0">
      <v-col
        cols="12"
        class="pa-6 pb-0"
      >
        <h1>Charities</h1>
      </v-col>
      <v-col
        cols="4"
        class="pa-6 pt-2 pb-0"
      >
        <v-autocomplete
          v-model.lazy="selectedCity"
          :items="charityCities"
          :label="'City'"
          item-text="name"
          item-value="name"
          prepend-inner-icon="mdi-navigation-variant"
          hide-details=""
          x-large
          outlined
          clearable
          multiple
        />
      </v-col>
      <v-col
        cols="4"
        class="pa-6 pt-2 pb-0"
      >
        <v-select
          v-model.lazy="selectedProvince"
          :items="charityProvince"
          :label="'Province'"
          item-text="name"
          item-value="name"
          prepend-inner-icon="mdi-home-group"
          hide-details=""
          x-large
          outlined
          clearable
          multiple
          @clear="selectedProvince = ''"
        />
      </v-col>
      <v-col
        cols="4"
        class="text-end pa-6 pt-2 pb-0"
      >
        <v-btn
          color="primary"
          outlined
          @click="openCharityCreateForm"
        >
          <v-icon
            small
            class="mr-1"
          >
            mdi-plus
          </v-icon>
          New Charity
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <charity-table
          ref="charityTable"
          :filters="filters"
        >
          <template slot-scope="{item}">
            <!-- <v-tooltip top>
              <template #activator="{ on, attrs }">
                <font-awesome-icon
                  class="icon-hover mr-3"
                  v-bind="attrs"
                  :icon="['fabs','user-pen']"
                  v-on="on"
                  @click="openCharityUpdateForm(item)"
                />
              </template>
              <span>Edit Charity</span>
            </v-tooltip> -->
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <font-awesome-icon
                  class="icon-hover mr-3"
                  v-bind="attrs"
                  :icon="['fabs','user-pen']"
                  v-on="on"
                  @click="loginAsCharityRep(item)"
                />
              </template>
              <span>Login As (To Edit Charity Details)</span>
            </v-tooltip>
            <!-- <v-tooltip top>
              <template #activator="{ on, attrs }">
                <font-awesome-icon
                  class="icon-hover mr-3"
                  v-bind="attrs"
                  :icon="['fabs', 'chalkboard']"
                  v-on="on"
                  @click="openCharityPositionDialog(item)"
                />
              </template>
              <span>Board</span>
            </v-tooltip> -->
            <!-- <v-tooltip top>
              <template #activator="{ on, attrs }">
                <font-awesome-icon
                  class="icon-hover mr-3"
                  v-bind="attrs"
                  :icon="['fabs', 'layer-group']"
                  v-on="on"
                  @click="openCharityPostingDialog(item)"
                />
              </template>
              <span>Postings</span>
            </v-tooltip> -->
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <font-awesome-icon
                  class="icon-hover mr-3"
                  style="color:red"
                  v-bind="attrs"
                  :icon="['fabs', 'trash-can']"
                  v-on="on"
                  @click="softDelete(item.id)"
                />
              </template>
              <span>Delete Charity</span>
            </v-tooltip>
          </template>
        </charity-table>
      </v-col>
    </v-row>
    <charity-template-dialog
      ref="charityTemplateDialog"
      :title="charityFormTitle"
      @charity-success="notifySuccess"
    />
    <charity-edit-template-dialog
      ref="charityEditTemplateDialog"
      :title="charityFormTitle"
      :charity="charity"
      @charity-success="notifySuccess"
    />
    <charity-rep-table-template-dialog
      ref="charityRepTableTemplateDialog"
      :title="charityRepsTableFormTitle"
      :is-board-match-admin="true"
    />
    <charity-position-template-dialog
      ref="charityPositionTemplateDialog"
      :title="charityPositionDialogTitle"
      :charity="charity"
    />
    <charity-posting-template-dialog
      ref="charityPostingTemplateDialog"
      :title="charityPostingDialogTitle"
      :charity="charity"
    />
    <user-authenticate-as-dialog
      ref="authenticateAsDialog"
      :is-charity="true"
      :charity-id="charityId"
    />
  </v-container>
</template>

<script>
import CharityPositionTemplateDialog from '@/components/charity/charityPosition/CharityPositionTemplateDialog';
import CharityPostingTemplateDialog from '@/components/charity/charityPosting/CharityPostingTemplateDialog';
import CharityTemplateDialog from '@/components/charity/CharityTemplateDialog';
import CharityEditTemplateDialog from '@/components/charity/CharityEditTemplateDialog';
import CharityRepTableTemplateDialog from '@/components/charity/charityRep/CharityRepTableTemplateDialog';
import CharityTable from '@/components/charity/CharityTable.vue';
import UserAuthenticateAsDialog from '@/components/user/UserAuthenticateAsDialog';

export default {
  components: {
    CharityTemplateDialog,
    CharityEditTemplateDialog,
    CharityRepTableTemplateDialog,
    CharityTable,
    CharityPositionTemplateDialog,
    CharityPostingTemplateDialog,
    UserAuthenticateAsDialog,
  },
  data: () => ({
    charities: [],
    charitiesPerPageProps: {
      'items-per-page-options': [10, 20, 50, -1],
    },
    charitiesPerPage: 20,
    total: 0,
    loading: false,
    charity: null,
    charityId: null,
    charityFormTitle: 'Create Charity',
    charityRepsTableFormTitle: '',
    charityPositionDialogTitle: '',
    charityPostingDialogTitle: '',
    pagination: null,
    selectedProvince: null,
    selectedCity: null,
    charityCities: [],
    charityProvince: [],

  }),
  computed: {
    filters() {
      const filters = [];
      filters.push({ name: 'AdminOverride', values: [1] });
      // city
      if (this.selectedCity && this.selectedCity.length > 0) {
        filters.push({ name: 'City', values: this.selectedCity });
      }
      if (this.selectedProvince && this.selectedProvince.length > 0) {
        filters.push({ name: 'Province', values: this.selectedProvince });
      }
      return filters;
    },
    charityheaders() {
      const headers = [];

      headers.push({ text: 'Name', value: 'name' });
      headers.push({ text: 'Email', value: 'email' });
      headers.push({ text: 'Chariable Number', value: 'chariableNumber' });
      // headers.push({ text: 'Mandate', value: 'mandate' });
      headers.push({ text: 'Year of Incorporation', value: 'yearOfIncorporation' });
      // headers.push({ text: 'Website', value: 'website' });
      headers.push({ text: 'Scope', value: 'scope' });
      headers.push({ text: 'Type', value: 'type' });
      headers.push({ text: 'Address', value: 'address' });
      headers.push({ text: 'Phone', value: 'phone' });
      headers.push({ text: 'Actions', value: 'actions', align: 'right' });

      return headers;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.loadingData = Promise.all(
        [
          await this.getCharityCities(),
          await this.getCharityProvince(),
        ],
      );
      await this.loadingData;
    },
    openCharityCreateForm() {
      this.charity = null;
      this.$refs.charityEditTemplateDialog.closeCharityForm();
      this.charityFormTitle = 'Create Charity';
      this.$refs.charityTemplateDialog.openCharityForm();
    },
    openCharityUpdateForm(charity) {
      this.$refs.charityTemplateDialog.closeCharityForm();
      this.charityFormTitle = 'Update Charity';
      this.charity = JSON.parse(JSON.stringify(charity));
      if (this.charity.email === null) this.charity.email = { name: '' };
      this.$refs.charityEditTemplateDialog.openCharityForm();
    },
    notifySuccess(message) {
      this.$bus.$emit('showSuccess', message);
      this.$refs.charityTable.refresh();
    },
    openCharityRepForm(charity) {
      this.charityRepsTableFormTitle = 'Charity Users';
      this.$refs.charityRepTableTemplateDialog.openCharityRepTableForm(charity.id);
    },
    loginAsCharityRep(charity) {
      this.charityId = charity.id;
      this.$refs.authenticateAsDialog.openLoginAsCharityRep();
    },
    openCharityPositionDialog(charity) {
      this.charityPositionDialogTitle = `Board of ${charity.name}`;
      this.charity = charity;
      this.$refs.charityPositionTemplateDialog.openPositionDialog();
    },
    openCharityPostingDialog(charity) {
      this.charityPostingDialogTitle = `Postings of ${charity.name}`;
      this.charity = charity;
      this.$refs.charityPostingTemplateDialog.openPostingDialog();
    },
    async softDelete(id) {
      if (!confirm('Are you sure you would like to delete this charity?')) return;
      const response = await this.$api.charity.softDelete(id);
      if (response === true) {
        this.notifySuccess('Charity deleted');
      } else {
        this.$bus.$emit('showError');
      }
    },
    async getCharityCities() {
      const cities = await this.$api.charity.getCities();
      this.charityCities = [];
      cities.forEach((x) => {
        const splitName = x.split(' ');
        for (let i = 0; i < splitName.length; i += 1) {
          if (splitName[i][0]) splitName[i] = splitName[i][0].toUpperCase() + splitName[i].substr(1);
        }
        this.charityCities.push({ name: splitName.join(' '), value: x });
      });
    },
    async getCharityProvince() {
      const province = await this.$api.charity.getProvince();
      this.charityProvince = province.map((x) => {
        const splitName = x.split(' ');
        for (let i = 0; i < splitName.length; i += 1) {
          if (splitName[i][0]) splitName[i] = splitName[i][0].toUpperCase() + splitName[i].substr(1);
        }
        return splitName.join(' ');
      });
    },
  },
};

</script>
